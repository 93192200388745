import axios from "axios";
const API = process.env.VUE_APP_URL_HORUS;

export default class Laboratory {
  async count_laboratory(apiKey, search) {
    const request = await axios.post(API + "omember/laboratory/count", search, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // Search

  async store_identification(apiKey, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/identification",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async check_store_identification(apiKey, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/check",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_laboratory_contact(apiKey, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/contact",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_technical(apiKey, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/technical",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_worker(apiKey, laboratoryUUID, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/worker?laboratoryUUID=" + laboratoryUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_company_in_laboratory(apiKey, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/company",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_worker(apiKey, workerUUID) {
    let request = await axios.post(
      API + "omember/laboratory/delete/worker?&workerUUID=" + workerUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_bank(apiKey, laboratoryUUID, form) {
    let request = await axios.post(
      API + "omember/laboratory/store/bank?laboratoryUUID=" + laboratoryUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_bankinfo(apiKey, dataBankUUID) {
    let request = await axios.post(
      API + "omember/laboratory/delete/bank?&dataBankUUID=" + dataBankUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_laboratory_details(apiKey, laboratoryUUID) {
    let request = await axios.get(
      API + "omember/laboratory/details?laboratoryUUID=" + laboratoryUUID,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_laboratories_from_company(apiKey, companyUUID, page, size) {
    let request = await axios.get(
      API +
        "omember/laboratory/list?companyUUID=" +
        companyUUID +
        "&size=" +
        size +
        "&page=" +
        page,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async search_laboratory(apiKey, strSearch) {
    const request = await axios.post(
      API + "omember/laboratory/search",
      { search: strSearch },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async search_laboratories_v2(apiKey, form, pagination) {
    const request = await axios.post(
      API +
        "omember/laboratory/search/v2?page=" +
        pagination.currentPage +
        "&size=" +
        pagination.size,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_sales(apiKey, laboratoryUUID, form) {
    let request = await axios.post(
      API +
        "omember/laboratory/store/salesfollow?laboratoryUUID=" +
        laboratoryUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_sales(apiKey, salesFollowUUID) {
    let request = await axios.post(
      API +
        "omember/laboratory/delete/salesfollow?salesFollowUUID=" +
        salesFollowUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async link_labo_to_area(apiKey, area, laboratoryUUID) {
    const request = await axios.post(
      API + "omember/laboratory/link/area?laboratoryUUID=" + laboratoryUUID,
      area,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async unlink_area_lab(apiKey, laboratoryUUID) {
    const request = await axios.post(
      API + "omember/laboratory/unlink/area?laboratoryUUID=" + laboratoryUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Conditions commerciales et contractuelles

  async store_commercial_contract(apiKey, laboratoryUUID, form) {
    const request = await axios.post(
      API +
        "omember/laboratory/store/commercialcontract?laboratoryUUID=" +
        laboratoryUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async search_referent(apiKey, body) {
    const request = await axios.post(API + "omember/referent/search", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async search_zone(apiKey, searchText) {
    const request = await axios.post(
      API + "omember/area/search",
      {
        search: searchText,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_addendum(apiKey, paramUUID, form) {
    const request = await axios.post(
      API + "omember/addendum/store?uuid=" + paramUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_addendum(apiKey, addendumUUID) {
    const request = await axios.post(
      API + "omember/addendum/delete?addendumUUID=" + addendumUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async edit_previsionnalsales(apiKey, laboratoryUUID, form) {
    const request = await axios.post(
      API +
        "omember/laboratory/store/previsionnalsales?laboratoryUUID=" +
        laboratoryUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_previsionnalsales(apiKey, laboratoryUUID) {
    const request = await axios.post(
      API +
        "omember/laboratory/delete/previsionnalsales?laboratoryUUID=" +
        laboratoryUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_accousticcabin(apiKey, laboratoryUUID, form) {
    const request = await axios.post(
      API +
        "omember/laboratory/store/accousticcabin?laboratoryUUID=" +
        laboratoryUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_accousticcabin(apiKey, accousticcabinUUID) {
    const request = await axios.post(
      API +
        "omember/laboratory/delete/accousticcabin?accousticCabinUUID=" +
        accousticcabinUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_laboratory(apiKey, laboratoryUUID) {
    const request = await axios.post(
      API + "omember/laboratory/delete?laboratoryUUID=" + laboratoryUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async laboratory_export_file(apiKey, body = {}, format) {
    const request = await axios.post(
      API + "omember/laboratory/export/" + format,
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
        responseType: "blob",
      }
    );
    return request.data;
  }

  async rum_generate(apiKey, laboratoryUUID) {
    const request = await axios.post(
      API + "omember/laboratory/rum/generate?laboratoryUUID=" + laboratoryUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async check_rum_validity(apiKey, form) {
    const request = await axios.post(
      `${API}omember/laboratory/rum/check`,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_laboratory_filterKey_list(apiKey) {
    const request = await axios.post(
      API + "omember/laboratory/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
